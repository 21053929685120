export function scrollOnClick(event) {
    event.preventDefault();
    const target = event.target.getAttribute('href');

    const anchor = document.querySelector(target);
    history.replaceState(null, null, target);

    anchor.scrollIntoView({
        behavior: 'smooth'
    });
}
