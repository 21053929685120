<script>
    import Container from "../component/Container.svelte";
    import Headline from "../component/Headline.svelte";
    import Checkmark from "../icons/check-solid.svg";

    const services = [
        {
            name: 'Färben',
            features: ['Blondierung', 'Strähnen', 'Typberatung'],
            type: 'color'
        },
        {
            name: 'Hochsteckfrisur',
            features: ['Hochzeit', 'Testfrisur', 'Beratung'],
            type: 'wedding'
        },
        {
            name: 'Perücken',
            features: ['Echthaar', 'nach Maß', 'Typgerecht'],
            type: 'old'
        },
    ];
</script>

<Container type="lightgrey">
    <Headline>Services</Headline>

    <div class="container">

        {#each services as service}
            <article  class="{service.type}">
                <header>
                    {service.name}
                </header>

                <ul>
                    {#each service.features as feature}
                        <li>
                            <span class="icon">
                                <svelte:component this={Checkmark} width="16" />
                            </span>
                            {feature}
                        </li>
                    {/each}
                </ul>
            </article>
        {/each}
    </div>

</Container>

<style>
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 50px 50px 0 50px;
    }

    @media only screen and (max-width: 1000px) {
        .container {
            padding: 50px;
        }
    }

    @media only screen and (max-width: 600px) {
        .container {
            padding: 20px;
        }
    }

    article {
        align-items: center;
        width: 30%;
        border-radius: 5px;
        margin-bottom: 50px;

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .wedding {
        background-image: var(--gradient-transparent2), url("/wedding.jpg");
    }

    .old {
        background-image: var(--gradient-transparent2), url("/old.jpg");
    }

    .color {
        background-image: var(--gradient-transparent2), url("/color.jpg");
    }


    @media only screen and (max-width: 1000px) {
        article {
            width: 100%;
        }
    }

    header {
        border-radius: 5px 5px 0 0;
        color: #ffffff;
        padding: 250px 40px 0;
        font-size: 28px;
    }

    ul {
        color: var(--white);
        padding: 0 40px 10px;
        font-size: 22px;
        list-style: none;
    }

    li {
        margin-bottom: 10px;
    }

    .icon {
        margin-right: 10px;
    }

    footer {
        text-align: center;
        padding: 40px;
    }

    p {
        text-align: center;
    }
</style>
