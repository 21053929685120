<script>
    import Container from "../component/Container.svelte";
    import Impressum from "./Impressum.svelte";

    let impressum = false;

    function onImpressumOpen() {
        impressum = true;
    }

    function onImpressumClose() {
        impressum = false;
    }
</script>

<Container type="dark">
    <div>
        <a href="#impressum" on:click|preventDefault={onImpressumOpen}>Impressum</a>
    </div>
</Container>

{#if impressum}
    <Impressum on:close={onImpressumClose}/>
{/if}

<style>
    div {
        color: #fff;
        text-align: center;
        padding: 40px 0;
    }

    a {
        color: var(--white);
    }
</style>
