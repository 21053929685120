<script>
    export let type = 'white';
</script>


<div class="container" class:dark={type === 'dark'} class:white={type === 'white'} class:black={type === 'black'} class:lightgrey={type === 'lightgrey'}>
    <div class="box">
        <slot></slot>
    </div>
</div>


<style>
    .container {
        display: flex;
        justify-content: center;
    }

    .box {
        max-width: 1280px;
        width: 100%;
    }

    .dark {
        background: var(--grey);
    }

    .white {
        background: var(--white);
    }

    .black {
        background: var(--black);
    }

    .lightgrey {
        background: var(--lightgrey);
    }
</style>
