<script>
    import {createEventDispatcher} from 'svelte';

    const dispatch = createEventDispatcher();

    function closeModal() {
        dispatch('close');
    }
</script>

<div class="modal" on:click={closeModal}>
    <div class="inner">
        <div class="modal-content" on:click={(event) => {event.stopPropagation()}}>
            <span class="close" on:click={closeModal}>X</span>
            <slot></slot>
        </div>
    </div>
</div>

<style>
    .modal {
        display: flex;
        justify-content: center;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .inner {
        max-width: 1280px;
        width: 100%;
        height: 100%;
    }

    .modal-content {
        background-color: var(--white);
        padding: 50px;
        margin: 50px 20px;
    }

    @media only screen and (max-width: 600px) {
        .modal-content {
            padding: 20px;
        }
    }

    .close {
        color: var(--grey);
        float: right;
        font-size: 36px;
    }

    .close:hover,
    .close:focus {
        color: var(--black);
        text-decoration: none;
        cursor: pointer;
    }
</style>
