<script>
    import {scrollOnClick} from "../helper";

    const list = [
        {
            value: 'about-me',
            label: 'Über mich'
        },
        {
            value: 'address',
            label: 'Adresse'
        },
        {
            value: 'news',
            label: 'Neuigkeit'
        },
        {
            value: 'service',
            label: 'Service'
        },
        {
            value: 'price',
            label: 'Preise'
        },
        {
            value: 'contact',
            label: 'Termin'
        },
    ];

    let y;
</script>

<svelte:window bind:scrollY={y}/>

<nav class:nav-detached={y > 150}>
    <div class="inner">
        <div class="brand">
            <a href="#home" on:click={scrollOnClick} class:display={y > 150}>
                Angelikas Haarstudio
            </a>
        </div>
        <ul>
            {#each list as item}
                <li>
                    <a class="link" href="#{item.value}" on:click={scrollOnClick}>{item.label}</a>
                </li>
            {/each}
        </ul>
    </div>
</nav>

<style>
    nav {
        display: flex;
        justify-content: center;

        width: 100%;
        position: fixed;
        z-index: 1;
        transition: background-color .5s;
    }

    .inner {
        max-width: 1280px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .nav-detached {
        background: var(--grey);
    }

    .brand {
        flex-grow: 1;
    }

    .brand a {
        display: inline-block;
        color: var(--white);
        font-size: 32px;
        padding: 20px 40px;
        text-decoration: none;
        opacity: 0;
        transition: opacity .5s;
    }

    .brand a.display {
        opacity: 1;
    }

    .brand a:hover {
        background: rgba(255, 255, 255, 0.2);
    }

    @media only screen and (max-width: 1000px) {
        .brand a {
            padding: 20px;
        }
    }

    ul {
        display: flex;
        padding: 0;
        margin: 0;
    }

    @media only screen and (max-width: 1000px) {
        ul {
            display: none;
        }
    }

    li {
        list-style: none;
        color: white;
    }

    .link {
        display: block;
        padding: 20px 40px;
        text-decoration: none;
        color: var(--white);
    }

    .link:hover {
        background: rgba(255, 255, 255, 0.2);
    }
</style>
